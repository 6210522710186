import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useQuery} from '@apollo/client';
import {GET_MY_USER} from "gql/queries/GetUser";
import LinearProgress from "@material-ui/core/LinearProgress";
import NewUserDialog from "./NewUserDialog";

const UserRegister = () => {
    const { user } = useAuth0();

    const {loading, error, data} = useQuery(GET_MY_USER, {
        variables: {userID: user.sub},
    });

    if (loading) {
        return <LinearProgress/>
    }

    if (error) {
        return <div>Oops....   {error.message}</div>
    }
    if (data.queryUser.length > 0) {
       return <div/>
    }
    return <NewUserDialog user={user}/>
};

export default UserRegister;
