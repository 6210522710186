import { gql } from "@apollo/client";

export const REGISTER_USER = gql`
  mutation AddUser(
    $userID: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $username: String!
    $displayName: String!
    $avatar: String
  ) {
    addUser(
      input: [
        {
          userID: $userID
          firstName: $firstName
          lastName: $lastName
          email: $email
          username: $username
          displayName: $displayName
          avatar: $avatar
        }
      ]
    ) {
      user {
        id
        userID
        prefix
        firstName
        lastName
        suffix
        email
        phone
        birthday
        username
        city {
          name
          state {
            name
          }
        }
        homepage
        tagline
        bio
      }
    }
  }
`;
