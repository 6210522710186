import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { MailOutlined } from "@material-ui/icons";

const CoverPhoto = styled.div`
  width: 100%;
  height: 179px;
  background-image: url("https://source.unsplash.com/random");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-top: 0px;
`;

const ProfileHeaderGrid = styled.div`
  display: grid;
  margin-left: 25px;
  padding-bottom: 25px;
  padding-top: 10px;
  grid-template-columns: 160px auto 50px;
`

const Avatar = styled.img`
  border-radius: 100%;
  display: flex;
  flex: 0 0 150px;
  height: 150px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 150px;
  margin-top: -100px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12),
    0 4px 4px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.12),
    0 16px 16px rgba(0, 0, 0, 0.12);
  z-index: 1000;
`;

const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
    text: {
      margin: "0px",
    },
    button: {
      marginTop: "15px",
    },
  }))

export default function ProfileHeader(props) {
    const classes = useStyles()

    const {
        userID,
        firstName,
        lastName,
        email,
        avatar,
        displayName,
        prefix,
        suffix,
        birthday,
        phone,
        tagline,
        bio,
        homepage,
      } = props.user;

  return (
    <Paper>
      <CoverPhoto />
      <ProfileHeaderGrid>
        <Avatar src={avatar} alt={displayName} />
        <div>
          <Typography variant="h4" gutterBottom className={classes.text}>
            {prefix} {firstName} {lastName} {suffix}
          </Typography>
          <Typography variant={"subtitle1"}>{tagline}</Typography>
          <Typography variant={"subtitle2"}>Jacksonville, Florida</Typography>
        </div>
        <div>
          <IconButton aria-label="edit profile" size="small" color="primary">
            <MailOutlined />
          </IconButton>
        </div>
      </ProfileHeaderGrid>
    </Paper>
  );
}
