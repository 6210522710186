import React from 'react';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";

const Bio = (props) => {

    if (props.bio) return (
        <Paper>
            <CardContent>
                <Typography variant="h5" component="h2">
                    Bio
                </Typography>
                <Typography variant="body2" component="p">
                    {props.bio}
                </Typography>
            </CardContent>
        </Paper>
    );
    return <div/>
};

export default Bio;
