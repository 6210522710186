import {useQuery} from "@apollo/client";
import LinearProgress from "@material-ui/core/LinearProgress";
import {SEARCH_USER} from "gql/queries/SearchUser";
import React, {useState} from 'react';

const useFuzzySearch = (search) => {
    const {loading, error, data} = useQuery(SEARCH_USER, {
        variables: {search},
    });
    if (search === '/^.*.*$/i') {
        return null
    }
    return data
};

export default useFuzzySearch;
