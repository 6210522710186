import React, {useState} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { GET_USER } from "gql/queries/GetUser";
import Bio from "components/Bio";
import { editProfileVar } from "cache";
import ProfileHeader from "atomic/organisms/ProfileHeader";
console.log(editProfileVar())


const Container = styled.div`
  display: grid;
  height: 100%;
  width: 100vw;
  grid-template-columns: 1fr 790px 1fr;
  grid-template-areas: "left main right";
  padding-top: 10px;
  column-gap: 10px;
`;

const Left = styled.div`
  grid-area: left;
  background-color: green;
`;

const Main = styled.main`
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Right = styled.div`
  grid-area: right;
  background-color: aqua;
`;

const Profile = (props) => {
  const { user } = useAuth0();
  // const localState = useQuery(GET_LOCAL_STATE)
  const { loading, error, data } = useQuery(GET_USER, {
    variables: { username: props.match.params.username },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error {error.message}</p>;

  if (data.queryUser.length === 0) {
    return <div />;
  }

  const {
    userID,
    firstName,
    lastName,
    email,
    avatar,
    displayName,
    prefix,
    suffix,
    birthday,
    phone,
    tagline,
    bio,
    homepage,
    editProfile
  } = data.queryUser[0];


  return (
    <Container>
      <Main>
        <ProfileHeader user={data.queryUser[0]} uid={user.sub} />
        <Bio bio={bio} />
      </Main>
    </Container>
  );
};

export default Profile;
