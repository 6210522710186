import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Auth0Provider} from "@auth0/auth0-react";
import {ApolloClient, ApolloProvider, gql} from '@apollo/client';
import 'fontsource-roboto';
import { cache } from 'cache';

const typeDefs = gql`
    extend type User {
        editProfile:  Boolean!
    }
`;

const client = new ApolloClient({
    // uri: 'https://us-central1-associate-finder.cloudfunctions.net/associate-finder-graphql',
    uri: 'https://wired-iron.us-west-2.aws.cloud.dgraph.io/graphql',
    cache,
    typeDefs,
    connectToDevTools: true
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <Auth0Provider
            domain="associate-finder-prod.us.auth0.com"
            clientId="2nfdnRgwYrCr4JO5lmNLBb4dg29zwi4C"
            redirectUri={window.location.origin}
        >
            <App/>
        </Auth0Provider>
    </ApolloProvider>,
    document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
