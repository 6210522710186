import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import CardContent from '@material-ui/core/CardContent';
import { UPDATE_USER } from "gql/mutations/UpdateUser";
import "date-fns";
import { editProfileVar } from 'cache';

function ProfileEditForm(props) {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [prefix, setPrefix] = useState(props.user.prefix);
  const [firstName, setFirstName] = useState(props.user.firstName);
  const [lastName, setLastName] = useState(props.user.lastName);
  const [suffix, setSuffix] = useState(props.user.suffix);
  const [email, setEmail] = useState(props.user.email);
  const [phone, setPhone] = useState(props.user.phone);
  const [birthday, setBirthday] = useState(
    props.user.birthday.substring(0, 10)
  );
  const [tagline, setTagline] = useState(props.user.tagline);
  const [bio, setBio] = useState(props.user.bio);
  const [homepage, setHomepage] = useState(props.user.homepage);
  const profilePath = `/${props.user.username}`
  const [updateUser, { error, loading }] = useMutation(UPDATE_USER, {
    onError: (error) => console.log("ERROR: " + error),
    onCompleted: () => {
      history.push(profilePath)
      editProfileVar(false)
    },
  });



  return (
    <div>
      <Paper>
        <CardContent>
      <TextField
        autoComplete="honorific-prefix"
        autoFocus
        margin="dense"
        id="prefix"
        label="Prefix"
        type="text"
        defaultValue={prefix}
        onChange={(e) => {
          setPrefix(e.target.value);
        }}
      />
      <TextField
        autoComplete="given-name"
        margin="dense"
        id="firstName"
        label="First name"
        type="text"
        defaultValue={firstName}
        fullWidth
        onChange={(e) => {
          setFirstName(e.target.value);
        }}
      />
      <TextField
        margin="dense"
        id="lastName"
        label="Last name"
        type="text"
        defaultValue={lastName}
        fullWidth
        autoComplete="family-name"
        onChange={(e) => {
          setLastName(e.target.value);
        }}
      />
      <TextField
        margin="dense"
        id="suffix"
        label="Suffix"
        type="text"
        defaultValue={suffix}
        autoComplete="honorific-suffix"
        onChange={(e) => {
          setSuffix(e.target.value);
        }}
      />
      {/*<SearchLocationInput />*/}
      <TextField
        margin="dense"
        id="email"
        label="Email Address"
        type="email"
        defaultValue={email}
        fullWidth
        autoComplete="email"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      {/*TODO: Revisit to figure out how to properly mask this input.*/}
      <TextField
        margin="dense"
        id="phone"
        label="Phone"
        type="tel"
        defaultValue={phone}
        fullWidth
        autoComplete="tel"
        onChange={(e) => {
          setPhone(e.target.value);
        }}
      />
      <TextField
        margin="dense"
        autoComplete="bday"
        id="date"
        label="Birthday"
        type="date"
        defaultValue={birthday}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          setBirthday(e.target.value);
        }}
      />

      <TextField
        margin="dense"
        id="homepage"
        label="Homepage"
        type="url"
        defaultValue={homepage}
        fullWidth
        autoComplete="url"
        onChange={(e) => {
          setHomepage(e.target.value);
        }}
      />
      <TextField
        margin="dense"
        id="tagline"
        label="Tagline"
        type="text"
        placeholder="Title or short description of who you are."
        defaultValue={tagline}
        fullWidth
        onChange={(e) => {
          setTagline(e.target.value);
        }}
      />
      <TextField
        multiline
        rows={2}
        rowsMax={4}
        margin="dense"
        id="bio"
        label="Bio"
        type="text"
        placeholder=""
        defaultValue={bio}
        fullWidth
        onChange={(e) => {
          setBio(e.target.value);
        }}
      />
      <div>{error && error.message}</div>
      <Button
        onClick={() => {
          history.push(profilePath);
          editProfileVar(false) 
        }}
        color="primary"
      >
        Cancel
      </Button>
      {/*TODO:  Handle the promise for updateUser*/}
      <Button
        disabled={loading}
        onClick={(e) => {
          e.preventDefault();
          // console.log(props.user)
          updateUser({
            variables: {
              userID: props.user.userID,
              firstName: firstName,
              lastName: lastName,
              email: email,
              prefix: prefix,
              suffix: suffix,
              phone: phone,
              birthday: birthday,
              tagline: tagline,
              bio: bio,
              homepage: homepage,
            },
          }).catch((err) => console.log(err));
        }}
        color="primary"
      >
        Save
      </Button>
      </CardContent>
      </Paper>
    </div>
  );
}

export default ProfileEditForm;
