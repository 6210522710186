import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Landing from "../pages/landing";
import LinearProgress from "@material-ui/core/LinearProgress";

function Wrapper({children}) {
    const {
        isAuthenticated,
        isLoading,
        error,
    } = useAuth0();

    if (isLoading) {
        return <LinearProgress/>
    }

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (!isAuthenticated) {
        return (
            <div className="App">
                <Router>
                    {/*<Navbar/>*/}
                    <Switch>
                        <Route component={Landing}/>
                    </Switch>
                </Router>
            </div>
        );
    }
    return <>{children}</>;
}

export default Wrapper;
