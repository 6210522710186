import { gql } from "@apollo/client";

export const GET_USER = gql`
  query QueryUser($username: String) {
    queryUser(filter: { username: { eq: $username } }) {
      id
      userID
      firstName
      lastName
      email
      username
      avatar
      displayName
      prefix
      suffix
      phone
      birthday
      tagline
      bio
      homepage
      city {
        id
        name
        state {
          name
        }
      }
      editProfile @client
    }
  }
`;

export const GET_MY_USER = gql`
    query QueryUser($userID: String!) {
        queryUser(filter: {userID: {eq: $userID}}) {
            id
            userID
            firstName
            lastName
            email
            username
            avatar
            displayName
            prefix
            suffix
            phone
            birthday
            tagline
            bio
            homepage
            city {
                id
                name
                state {
                    name
                }
            }
        }
    }
`;
