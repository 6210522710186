import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $userID: String!
    $firstName: String
    $lastName: String
    $email: String
    $avatar: String
    $displayName: String
    $prefix: String
    $suffix: String
    $phone: String
    $birthday: DateTime
    $tagline: String
    $bio: String
    $homepage: String
  ) {
    updateUser(
      input: {
        filter: { userID: { eq: $userID } }
        set: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          avatar: $avatar
          displayName: $displayName
          prefix: $prefix
          suffix: $suffix
          phone: $phone
          birthday: $birthday
          tagline: $tagline
          bio: $bio
          homepage: $homepage
        }
      }
    ) {
      user {
        id
        userID
        firstName
        lastName
        email
        username
        avatar
        displayName
        prefix
        suffix
        phone
        birthday
        tagline
        bio
        homepage
        city {
          name
          state {
            name
          }
        }
      }
    }
  }
`;
