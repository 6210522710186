import {useQuery} from "@apollo/client";
import {useAuth0} from "@auth0/auth0-react";
import AvatarButton from "atomic/molecules/AvatarButton";
import LoginButton from "components/LoginButton";
import SearchBar from "components/SearchBar";
import SignupButton from "components/SignupButton";
import {GET_MY_USER} from "gql/queries/GetUser";
import React, {useState} from 'react';
import styled from "styled-components";
import {Link} from 'react-router-dom';

const Navbar = styled.nav`
  display: grid;
  grid-template-columns: 1fr 790px 1fr;
  grid-template-areas:
    "brand main right";
  position: static;
  height: 42px;
  width: 100vw;
  max-width: 100%;
  background-color: #1ea7fd;
  padding: 0;
  margin: 0;
  @media only screen 
  and (min-device-width : 768px) 
  and (max-device-width : 1024px)  {
    grid-template-columns: auto 1fr;
    grid-template-areas: "brand main";
  }
`

const Brand = styled.div`
  justify-self: right;
  padding-right: 20px;
  grid-area: brand;
  color: white;
  font-size: large;
  margin-top: 10px;
`

const MainBar = styled.div`
  //justify-self: end;  
`

const RightBar = styled.div`

`

const StyledAvatar = styled.img`
  height: 40px;
  border-radius: 100%;
  padding: 0;
  margin: 0;
`

const NavBar = () => {
    const [reset, setReset] = useState(0)
    const {logout, user, isAuthenticated} = useAuth0();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {loading, error, data} = useQuery(GET_MY_USER, {
        variables: {userID: user.sub},
    });
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error {error.message}</p>;
    const {
        avatar,
        displayName,
        firstName,
        username
    } = data.queryUser[0] || ""
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Navbar>
            <Brand>
                <Link
                    to={{
                        pathname: "/"
                    }}
                    onClick={() => {
                        setReset(Date.now)
                    }}
                    style={{textDecoration: 'none', color: 'white'}}
                >
                    Associate Finder
                </Link>
            </Brand>
            <MainBar>
                <SearchBar reset={reset}/>
            </MainBar>
            <RightBar>
                {!isAuthenticated && <LoginButton/>}
                {!isAuthenticated && <SignupButton/>}
                {isAuthenticated && (
                    <AvatarButton username={username} src={avatar} alt={displayName} firstName={firstName}/>
                )}
            </RightBar>
        </Navbar>
    );
};

export default NavBar;
