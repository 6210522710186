import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {REGISTER_USER} from "../gql/mutations/RegisterUser";
import {useMutation} from "@apollo/client";

const NewUserDialog = (props) => {
    const user = props.user
    const [open, setOpen] = useState(true);
    const [errors, setErrors] = useState("");
    const [firstName, setFirstName] = useState(user.given_name || "")
    const [lastName, setLastName] = useState(user.family_name || "")
    const [username, setUsername] = useState(user.username || "")
    const [addUser, {loading}] = useMutation(REGISTER_USER, {
        onError(e) {
            setErrors("Username must be unique")
        },
        onCompleted(t) {
            console.log("COMPLETE")
            window.location.replace(window.location.origin)
        }
    });

    return (
        <div>
            <Dialog open={open} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Almost there...</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Thanks for signing up for Associate Finder! Please give us a few more details to get started.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        autoComplete="given-name"
                        margin="dense"
                        id="firstName"
                        label="First name"
                        type="text"
                        defaultValue={firstName}
                        fullWidth
                        onChange={(e) => {
                            setFirstName(e.target.value)
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="lastName"
                        label="Last name"
                        type="text"
                        defaultValue={lastName}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => {
                            setLastName(e.target.value)
                        }}
                    />
                    <TextField
                        error={(errors !== "")}
                        margin="dense"
                        id="username"
                        label="Username"
                        type="text"
                        defaultValue={username}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => {
                            setUsername(e.target.value)
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    {errors}
                    <Button
                        color="primary"
                        disabled={loading}
                        onClick={e => {
                            e.preventDefault();
                            if (username.length < 3) {
                                setErrors("Username must be at least 3 characters")
                            } else  {
                                addUser({
                                    variables: {
                                        userID: user.sub,
                                        firstName: firstName,
                                        lastName: lastName,
                                        avatar: user.picture,
                                        email: user.email,
                                        username: username.toLowerCase(),
                                        displayName: username
                                    }
                                    // }).then((e) => {
                                    //     if (errors === "") {
                                    //         window.location.replace(window.location.origin)
                                    //     }
                                }).catch((e) => {
                                    console.log(e)
                                })
                            }
                        }}
                    >
                        Let's do this!
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default NewUserDialog;
