import {useAuth0} from "@auth0/auth0-react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import React, {useState} from 'react';
import {useHistory} from "react-router-dom";
import styled from 'styled-components';
import {Link} from 'react-router-dom'
import { editProfileVar } from "cache";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;  
  align-items: center;
  gap: 4px; 
`

const StyledAvatar = styled.img`
  height: 32px;
  border-radius: 100%;
  padding: 0;
`

const Name = styled.div`
    color: white;
    font-size: small;
`

const Button = styled.button`
    border: none;
    padding: 5px 8px 5px 8px;
    margin-left: 4px;
    margin-right: 12px;
    text-decoration: none;
    background: none;
    //color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, 
                transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    
    &:hover {
      background: #35b0fd;
    }
    &:focus {
      outline: none;  
      outline-offset: -4px;
    }
    &:active {
      transform: scale(0.99);
      border: 0;
    }
`

const Menu = styled.div`
  background-color: white;
  position: absolute;
  width: 125px;
  padding: 10px;
  z-index: 900;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12), 
              0 2px 2px rgba(0,0,0,0.12), 
              0 4px 4px rgba(0,0,0,0.12), 
              0 8px 8px rgba(0,0,0,0.12),
              0 16px 16px rgba(0,0,0,0.12); 
`

const MenuItem = styled.li`
  list-style-type: none;
  margin: 0;
  padding: 8px;
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #333333;

`

const MenuItemButton = styled.button`
  background: none;
  font: inherit;
  border: none;
  color: #333333; 
  cursor: pointer;
  &:focus {
    outline: none;  
    outline-offset: -4px;
  }
  &:active {
    transform: scale(0.99);
    border: 0;
  }
`

const AvatarButton = (props) => {
    const {logout, user, isAuthenticated} = useAuth0();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <Button onClick={handleClick}>
                    <ButtonContainer>
                        <StyledAvatar src={props.src} alt={props.alt}/>
                        <Name>
                            {props.firstName}
                        </Name>
                    </ButtonContainer>
                </Button>
                {open ? (
                    <Menu>
                        <StyledLink
                            onClick={handleClickAway}
                            to={{pathname: "/" + props.username}}
                        >
                            <MenuItem>Profile</MenuItem>
                        </StyledLink>
                        <StyledLink
                            onClick={() => {
                              editProfileVar(true)
                              handleClickAway()
                            }}
                            to={{pathname: "/edit"}}
                        >
                            <MenuItem>Edit Profile</MenuItem>
                        </StyledLink>
                        <MenuItemButton onClick={() => {
                            handleClickAway()
                            logout({returnTo: window.location.origin})
                        }}>
                            <MenuItem>Logout</MenuItem>
                        </MenuItemButton>
                    </Menu>
                ) : null}
            </div>
        </ClickAwayListener>
    );
};

export default AvatarButton;
