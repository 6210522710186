import { InMemoryCache, makeVar } from '@apollo/client';

export const cache = new InMemoryCache({
    typePolicies: {
        User: {
            fields: {
                editProfile() {
                    return editProfileVar();
                },
            },
        },
    },
});

export const editProfileVar = makeVar(false);
