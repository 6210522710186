import {gql} from "@apollo/client";

export const SEARCH_USER = gql`
    query QueryUser($search: String!) {
        queryUser(filter: {
            firstName: {regexp: $search},
            or: {lastName: {regexp: $search},
                or: {displayName: {regexp: $search}}}
        }) {
            id
            userID
            firstName
            lastName
            email
            username
            avatar
            displayName
            prefix
            suffix
            phone
            birthday
            tagline
            bio
            homepage
            city {
                name
                state {
                    name
                }
            }
        }
    }
`

