import SearchListCard from "components/SearchListCard";
import useFuzzySearch from "components/useFuzzySearch";
import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {useHistory} from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';

const ResultsContainer = styled.div`
  display: flex;
  justify-items: flex-start;
  width: 300px;
  height: auto;
  flex-direction: column;
`

const SearchInput = styled.input`
  padding: 6px;
  margin-top: 5px;
  border: none;
  font-size: 17px;
`

const UserCard = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: auto auto;
   grid-template-areas: 
    "avatar main"
    "avatar sub";
  padding: 5px;
`

const StyledAvatar = styled.img`
  grid-area: avatar;
  height: 40px;
  border-radius: 100%;
`

const Button = styled.button`
  padding: 0;
  margin: 0; 
  text-align: left;
  z-index: 100;
`

const Name = styled.div`
  grid-area: main;
  font-family: Roboto,sans-serif;
  font-size: medium;
`

const UserName = styled.div`
  grid-area: sub;
  font-family: Roboto,sans-serif;
  font-size: small;
  color: dimgrey;
`

const SearchBar = (props) => {
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const inputRef = useRef(null)
    const [search, setSearch] = useState('')
    const data = useFuzzySearch(search)
    let users
    if (data) {
        if (data.queryUser.length) {
            users = data.queryUser.map((user) =>
                <Button onClick={() => {
                    history.push(`/${user.username}`)
                    handleClickAway()
                    }}
                        key={user.id}
                >
                    <UserCard>
                        <StyledAvatar src={user.avatar}/>
                        <Name>{user.firstName} {user.lastName}</Name>
                        <UserName>{user.displayName}</UserName>
                    </UserCard>
                </Button>
            )
        }
    }

    useEffect(() => {
        return () => {
            inputRef.current.value = ''
        };
    }, [props.reset]);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
            <SearchInput
                ref={inputRef}
                type="text"
                placeholder="Search..."
                onFocus={handleClick}
                onChange={(e) => {
                    setSearch(`/^.*${e.target.value}.*$/i`)
                }}
            />
            {open ? (
                <ResultsContainer>
                    {users ? users : <div/>}
                </ResultsContainer>
            ) : null}
            </div>
        </ClickAwayListener>
    );
};

export default SearchBar;
