import React from "react";
import SignupButton from "../components/SignupButton";
import LoginButton from "../components/LoginButton";

const Landing = () => {

    return (
            <div>
                <h1>Landing</h1>
                <LoginButton/>
                <SignupButton/>
            </div>
    );
};

export default Landing;
