import NavBar from "atomic/organisms/NavBar";
import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Pages
import home from "pages/home";
import profile from "atomic/pages/profile";
import UserRegister from "components/UserRegister";
import Wrapper from "components/Wrapper";
import editProfile from 'atomic/pages/editProfile';

function App(client) {
  return (
    <div className="App">
      <Wrapper>
        <Router>
          <UserRegister />
          <NavBar />
          <Switch>
            <Route exact path="/" component={home} />
            <Route exact path="/edit" component={editProfile} />
            <Route exact path="/:username" component={profile} />
            <Route component={home} />
          </Switch>
        </Router>
      </Wrapper>
    </div>
  );
}

export default App;
