import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router-dom';

const UserCard = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: auto auto;
   grid-template-areas: 
    "avatar main"
    "avatar sub";
  padding: 5px;
`

const StyledAvatar = styled.img`
  grid-area: avatar;
  height: 40px;
  border-radius: 100%;
`

const Button = styled.button`
  padding: 0;
  margin: 0; 
  text-align: left;
  z-index: 100;
`

const Name = styled.div`
  grid-area: main;
  font-family: Roboto,sans-serif;
  font-size: medium;
`

const UserName = styled.div`
  grid-area: sub;
  font-family: Roboto,sans-serif;
  font-size: small;
  color: dimgrey;
`

const SearchListCard = (props) => {
    const history = useHistory();
    const {firstName, lastName, username, displayName, avatar} = props.user
    const profilePage = `/${username}`
    return (
        <Button onClick={() => {
            history.push(profilePage)
        }}>
            <UserCard>
                <StyledAvatar src={avatar}/>
                <Name>{firstName} {lastName}</Name>
                <UserName>{displayName}</UserName>
            </UserCard>
        </Button>
    );
};

export default SearchListCard;
