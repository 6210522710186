import React from 'react'

function EditProfileNavigation() {
    return (
        <div>
            NAVIGATION!        
        </div>
    )
}

export default EditProfileNavigation
