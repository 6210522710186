import React, { useState, useCallback, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";
import { GET_MY_USER } from "gql/queries/GetUser";
import { editProfileVar } from "cache";
import ProfileHeader from "atomic/organisms/ProfileHeader";
import ProfileEditForm from "atomic/organisms/ProfileEditForm";
import EditProfileNavigation from "atomic/organisms/EditProfileNavigation";

console.log(editProfileVar())

const Container = styled.div`
  display: grid;
  height: 100%;
  width: 100vw;
  grid-template-columns: 1fr 590px 200px 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "left main main right"
    "left form navigation right";
  padding-top: 10px;
  column-gap: 10px;
  row-gap: 10px;
`;

const Left = styled.div`
  grid-area: left;
  background-color: green;
`;

const Main = styled.main`
  grid-area: main;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Right = styled.div`
  grid-area: right;
  background-color: aqua;
`;

const Form = styled.div`
  grid-area: form;
`;

const Navigation = styled.nav`
  grid-area: navigation;
`;

const EditProfile = (props) => {
  // TODO:  Turn this into a component to be a wrapper around objects that need to be escaped.
  let history = useHistory();
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      //Do whatever when esc is pressed
      history.push(`/${username}`)
      editProfileVar(false)
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  const { user } = useAuth0();
  const { loading, error, data } = useQuery(GET_MY_USER, {
    variables: { userID: user.sub },
  });
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error {error.message}</p>;

  if (data.queryUser.length === 0) {
    return <div />;
  }

  const {
    userID,
    firstName,
    lastName,
    username,
    email,
    avatar,
    displayName,
    prefix,
    suffix,
    birthday,
    phone,
    tagline,
    bio,
    homepage,
    editProfile,
  } = data.queryUser[0];

  if (!editProfileVar()) {
    return (
    <Redirect push to="/" />
    )
  }
  return (
    <Container>
      <Main>
        <ProfileHeader user={data.queryUser[0]} uid={user.sub} />
      </Main>
      <Form>
        <ProfileEditForm user={data.queryUser[0]} />
      </Form>
      <Navigation>
        <EditProfileNavigation />
      </Navigation>
    </Container>
  );
};

export default EditProfile;
